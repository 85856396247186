<template>
  <div class="login-container">
    <div class="logo">
      <img class="logo-img" src="@/assets/img/login/logo-login.svg" alt="" />
    </div>
    <div class="login-content" @keyup.enter="handleLogin">
      <div class="img-area">
        <img src="@/assets/img/login/image-login.svg" alt="" />
      </div>
      <div class="login-area">
        <div class="login-wrapper">
          <div class="login-box">
            <div class="top-area">
              <div v-if="false" class="to-sign-up">Sign Up</div>
            </div>
            <div class="title-area">
              <div class="title">Login</div>
              <div class="discreption">
                Log into your NihaoKids account using an existing account
              </div>
            </div>

            <div class="middle-area">
              <div class="login-type">
                <div
                  class="type-title"
                  v-for="(itme, index) in ['Email', 'Phone']"
                  :key="index"
                  :class="{ active: index === currentLoginIndex }"
                  @click="changeLoginType(index)"
                >
                  {{ itme }}
                </div>
              </div>
              <div class="account">
                <el-input placeholder="Parent Email" v-model="email" clearable>
                </el-input>
              </div>
              <div class="pasword">
                <el-input
                  placeholder="Password"
                  v-model="password"
                  show-password
                ></el-input>
              </div>
            </div>
            <div class="login-botton" @click="handleLogin">
              <div class="log-in">Log In</div>
              <div class="arrow-img">
                <img src="@/assets/img/login/arrow-blue.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin } from "@/assets/js/axios/kidsApi.js";

export default {
  data() {
    return {
      email: "",
      password: "",
      currentLoginIndex: 0,
    };
  },
  methods: {
    changeLoginType(index) {
      this.currentLoginIndex = index;
    },
    async handleLogin() {
      // 测试kids账号
      // 测试新电脑
      // 开发账号 1
      // const username = "chad_student1@test.com";
      // const password = "123456";
      // 测试账号 2
      // const username = "testkids2@sidaorui.com";
      // const password = "Welldone@1978";
      // 测试账号 3
      // const username = "kidsTestStudent@123.com";
      // const password = "Welldone@1978";
      const loginData = {
        username: this.email,
        password: this.password,
        isKidPart: true,
      };
      const result = await userLogin(loginData);
      if (result && result.code === 200) {
        this.$message({ type: "success", message: "You've logged in" });
        // console.log(result);
        localStorage.userData = JSON.stringify(result.data);
        this.$store.commit("setUserInfo", result.data);
        this.$router.push({ name: "Home" });

        // 判断家长 or kids登录
        const currentUser = result.data.familyMember.filter(
          (item) => item.id === result.data.userSetting.userId
        );
        // console.log(currentUser);
        this.$store.commit("setUserIdentity", currentUser[0].isKids);
        localStorage.isKids = JSON.stringify(currentUser[0].isKids);
      } else if (result.code === -1) {
        this.$message({ type: "error", message: result.message });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
