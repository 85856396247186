var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass: "login-content",
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.handleLogin.apply(null, arguments)
          },
        },
      },
      [
        _vm._m(1),
        _c("div", { staticClass: "login-area" }, [
          _c("div", { staticClass: "login-wrapper" }, [
            _c("div", { staticClass: "login-box" }, [
              _c("div", { staticClass: "top-area" }, [
                false
                  ? _c("div", { staticClass: "to-sign-up" }, [
                      _vm._v("Sign Up"),
                    ])
                  : _vm._e(),
              ]),
              _vm._m(2),
              _c("div", { staticClass: "middle-area" }, [
                _c(
                  "div",
                  { staticClass: "login-type" },
                  _vm._l(["Email", "Phone"], function (itme, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "type-title",
                        class: { active: index === _vm.currentLoginIndex },
                        on: {
                          click: function ($event) {
                            return _vm.changeLoginType(index)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(itme) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "account" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Parent Email", clearable: "" },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pasword" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Password", "show-password": "" },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "login-botton", on: { click: _vm.handleLogin } },
                [
                  _c("div", { staticClass: "log-in" }, [_vm._v("Log In")]),
                  _vm._m(3),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "logo-img",
        attrs: { src: require("@/assets/img/login/logo-login.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-area" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/login/image-login.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-area" }, [
      _c("div", { staticClass: "title" }, [_vm._v("Login")]),
      _c("div", { staticClass: "discreption" }, [
        _vm._v(" Log into your NihaoKids account using an existing account "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow-img" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/login/arrow-blue.svg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }